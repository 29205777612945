<template>
    <section class="my-6 my-lg-7 my-xl-8 section-wrapper" v-in-viewport.once>
        <div
            :class="[
                videoPosition === 'left'
                    ? 'mx-auto mr-md-auto ml-md-0 pl-md-0 _img-left'
                    : 'mx-auto ml-md-auto mr-md-0 pr-md-0 _img-right',
                ['xs', 'sm'].includes($mq)
                    ? ''
                    : 'container-1650 container-fluid'
            ]"
        >
            <b-row class="no-gutters align-items-md-center fade-item">
                <div
                    class="mb-5 mb-md-0 video-col position-relative"
                    :class="
                        videoPosition === 'right'
                            ? 'order-md-2 ml-auto'
                            : 'mr-auto'
                    "
                >
                    <figure
                        v-if="testimonial.floating_image"
                        class="floating-image bck-shape"
                    >
                        <img
                            class="img-fluid"
                            :src="testimonial.floating_image.url"
                            :alt="testimonial.floating_image.alt"
                            :width="testimonial.floating_image.width"
                            :height="testimonial.floating_image.height"
                        />
                    </figure>
                    <video
                        :width="testimonial.video.width"
                        :height="testimonial.video.height"
                        muted
                        playsinline
                        autoplay
                        loop
                    >
                        <source
                            :src="testimonial.video.url"
                            :type="testimonial.video.mime_type"
                        />
                    </video>
                </div>
                <div class="text-col">
                    <div
                        :class="[
                            ['xs', 'sm'].includes($mq) ? 'px-4' : 'px-md-0',
                            videoPosition === 'left'
                                ? 'pl-md-4 pl-xl-5 ml-md-auto'
                                : 'pr-md-4 pr-xl-5 mr-md-auto'
                        ]"
                    >
                        <blockquote class="text">
                            <p>&ldquo;{{ testimonial.quote }}&rdquo;</p>
                            <footer class="d-flex flex-column mt-lg-4">
                                <span class="font-weight-semibold">{{
                                    testimonial.name
                                }}</span>
                                <cite class="no-italic mt-1">{{
                                    testimonial.short_description
                                }}</cite>
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </b-row>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        testimonial: {
            type: Object,
            required: true,
            default: () => {}
        },
        id: {
            type: Number,
            required: false,
            default: 0
        }
    },
    computed: {
        videoPosition() {
            return this.id % 2 === 0 ? "right" : "left";
        }
    }
};
</script>

<style lang="scss" scoped>
.video-col {
    @media screen and (min-width: 768px) {
        width: 50vw;
        max-width: 960px;
    }

    video {
        position: relative;
        max-width: 100%;
        height: auto;
    }
}
.text-col {
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px) {
        width: 40vw;
    }
    @media screen and (min-width: 1600px) {
        width: 35vw;
        max-width: 545px;
    }
}
.container-fluid {
    @media screen and (min-width: 1921px) {
        padding-left: 0;
        width: calc(100% - calc(calc(100% - 1400px) / 2));
        max-width: none;

        &._img-right {
            margin-left: calc(calc(100% - 1400px) / 2) !important;
        }
        &._img-left {
            margin-right: calc(calc(100% - 1400px) / 2) !important;
        }
    }
}
.floating-image {
    position: absolute;
    top: -35%;
    max-width: 50%;
}
._img-left .floating-image {
    left: -20%;
}
._img-right .floating-image {
    right: -20%;
}
</style>
