<template>
    <b-row class="pt-3 logo-wrapper no-gutters">
        <b-col
            v-for="partner in partners"
            :key="partner.ID"
            class="logo-item mt-4 mt-md-5 mr-xl-5"
        >
            <img :src="partner.url" :alt="partner.title" class="img-fluid" />
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: {
        partners: {
            type: Array,
            required: true,
            default: () => []
        }
    }
};
</script>

<style lang="scss" scoped></style>
