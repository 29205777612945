var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"in-viewport",rawName:"v-in-viewport.once",modifiers:{"once":true}}],staticClass:"my-6 my-lg-7 my-xl-8 section-wrapper"},[_c('div',{class:[
            _vm.videoPosition === 'left'
                ? 'mx-auto mr-md-auto ml-md-0 pl-md-0 _img-left'
                : 'mx-auto ml-md-auto mr-md-0 pr-md-0 _img-right',
            ['xs', 'sm'].includes(_vm.$mq)
                ? ''
                : 'container-1650 container-fluid'
        ]},[_c('b-row',{staticClass:"no-gutters align-items-md-center fade-item"},[_c('div',{staticClass:"mb-5 mb-md-0 video-col position-relative",class:_vm.videoPosition === 'right'
                        ? 'order-md-2 ml-auto'
                        : 'mr-auto'},[(_vm.testimonial.floating_image)?_c('figure',{staticClass:"floating-image bck-shape"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.testimonial.floating_image.url,"alt":_vm.testimonial.floating_image.alt,"width":_vm.testimonial.floating_image.width,"height":_vm.testimonial.floating_image.height}})]):_vm._e(),_c('video',{attrs:{"width":_vm.testimonial.video.width,"height":_vm.testimonial.video.height,"muted":"","playsinline":"","autoplay":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.testimonial.video.url,"type":_vm.testimonial.video.mime_type}})])]),_c('div',{staticClass:"text-col"},[_c('div',{class:[
                        ['xs', 'sm'].includes(_vm.$mq) ? 'px-4' : 'px-md-0',
                        _vm.videoPosition === 'left'
                            ? 'pl-md-4 pl-xl-5 ml-md-auto'
                            : 'pr-md-4 pr-xl-5 mr-md-auto'
                    ]},[_c('blockquote',{staticClass:"text"},[_c('p',[_vm._v("“"+_vm._s(_vm.testimonial.quote)+"”")]),_c('footer',{staticClass:"d-flex flex-column mt-lg-4"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.testimonial.name))]),_c('cite',{staticClass:"no-italic mt-1"},[_vm._v(_vm._s(_vm.testimonial.short_description))])])])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }