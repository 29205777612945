!<template>
    <div>
        <div
            class="our-story page-wrapper position-relative overflow-y-hidden"
            v-if="dataLoaded"
        >
            <div
                class="d-flex flex-column justify-content-center position-relative"
            >
                <ShapePicture class="shape-01" name="our-story/Shape-01" />
                <ShapePicture class="shape-02" name="our-story/Shape-02" />
                <div
                    class="container-longer container-fluid text-white intro-text-wrapper mt-7 mt-xl-9"
                >
                    <div class="row flex-column flex-md-row">
                        <div
                            class="col-12 col-md-6 pr-lg-5 pr-xl-7 d-flex align-items-center align-items-md-start"
                        >
                            <div class="d-flex flex-column mx-auto mr-lg-0">
                                <h1 class="mb-0">
                                    {{ object.acf.hero_title }}
                                </h1>
                                <span class="text">{{
                                    object.acf.hero_subtitle
                                }}</span>
                            </div>
                        </div>
                        <div
                            class="col-12 col-md-6 text mt-4 mt-md-0 pr-lg-5 pr-xl-9"
                            v-html="object.acf.hero_description"
                        ></div>
                    </div>
                </div>
                <div
                    class="container-longer container-fluid text-white intro-text-wrapper mt-5 mt-md-6 mt-lg-7 mt-xl-9 pt-xl-5"
                >
                    <div class="row">
                        <h2 class="col-12">
                            {{ object.acf.intro_title }}
                        </h2>
                        <div
                            class="col-12 text mt-4"
                            v-html="object.acf.intro_description"
                        ></div>
                    </div>
                </div>
            </div>

            <!-- testimonials -->
            <TestimonialSection
                v-for="(testimonial, id) in object.acf.testimonials"
                :key="testimonial.name"
                :testimonial="testimonial"
                :id="id"
            />

            <!-- partners -->
            <section class="position-relative">
                <!-- shape -->
                <ShapePicture class="shape-03" name="our-story/Shape-03" />
                <Partners
                    :title="object.acf.partners_title"
                    :partners="object.acf.partners"
                    :button="object.acf.partners_button"
                >
                </Partners>
            </section>

            <!-- bottom page shape -->
            <ShapePicture class="bottom-page-shape" name="our-story/Shape-04" />
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import seoMixin from "@/mixins/seoMixin";
import Partners from "../components/Partners.vue";
import TestimonialSection from "../components/TestimonialSection.vue";
import ShapePicture from "../components/ShapePicture.vue";
import Footer from "../components/Footer.vue";

export default {
    name: "our-story",
    mixins: [seoMixin],
    data() {
        return {
            object: {},
            dataLoaded: false
        };
    },
    components: {
        Partners,
        TestimonialSection,
        ShapePicture,
        Footer
    },
    created() {
        const pageApi = "wp-json/wp/v2/pages/684?_fields=id,slug,parent,acf";
        const data = axios.get(pageApi).then(res => {
            this.object = res.data;
            this.setSeo(
                this.object.acf.seo_title,
                this.object.acf.seo_description,
                "https://www.wearealive.com/WAA_default.jpeg",
                "https://www.wearealive.com" + this.$route.fullPath
            );
            this.dataLoaded = true;
            document.dispatchEvent(new Event("custom-render-trigger"));
            setTimeout(() => {
                $("body").css("visibility", "unset");
                this.$store.dispatch("setLoading", false);
            }, loadingDelay);
        });
    }
};
</script>
