<template>
    <section class="my-7 mb-xl-6" v-in-viewport.once>
        <b-container class="container-longer fade-item" fluid>
            <b-row class="no-gutters mt-4 mt-lg-5">
                <b-col cols="12" class="text-center">
                    <h2>{{ title }}</h2>
                    <p class="text mt-lg-3" v-if="text">
                        {{ text }}
                    </p>
                </b-col>
            </b-row>
            <PartnersLogos :partners="partners" />
            <b-row class="mt-4">
                <Button
                    class="mx-auto"
                    :url="button.url"
                    :bgColor="button.color"
                    >{{ button.label }}
                </Button>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import PartnersLogos from "./PartnersLogos.vue";
import Button from "./Button.vue";

export default {
    props: {
        title: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: false
        },
        partners: {
            type: Array,
            required: true
        },
        button: {
            type: Object,
            required: true
        }
    },
    components: {
        PartnersLogos,
        Button
    }
};
</script>

<style lang="scss" scoped></style>
